<template>
  <div class="404-page">
    <div class="container content-sm p-5 text-center">
      Błąd 404: strona nie istnieje
    </div>
  </div>
</template>

<script>

export default {
  name: "404",
  components: {
  }
};
</script>
